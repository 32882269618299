@mixin font-face($font-family, $file-path, $weight: normal, $style: normal ) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;

    src: url('#{$file-path}.woff2') format('woff2');
  }
}

@include font-face('Buduj-Sans', "../../../Fonts/BudujSans-Medium", 500);
@include font-face('Grotesk', "../../../Fonts/Grotesk", 900);
@include font-face('Oddval', "../../../Fonts/Oddval-SemiBold", 600);
@include font-face('Syne', "../../../Fonts/Syne-VariableFont", 500);
@include font-face('CraftworkGrotesk', "../../../Fonts/CraftworkGroteskGX", 500);
