.outlined-button {
  $parent: &;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid black;
  min-width: 159px;
  min-height: 49px;
  border-radius: 69px;
  background-color: transparent;
  transition: background-color 0.3s;
  text-decoration: none;
  padding: 0 15px;
  cursor: pointer;

  &:disabled {
    border-color: rgba(0, 0, 0, 0.5);
    pointer-events: none;

    #{$parent} {
      &__font {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &--size {
    &--medium {
      #{$parent} {
        &__font {
          color: black;
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          text-transform: uppercase;

          @media screen and (max-width: 768px) {
            font-size: 15px;
            line-height: 1;
          }
        }
      }
    }

    &--large {
      #{$parent} {
        &__font {
          color: black;
          text-align: center;
          font-size: 25px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          text-transform: uppercase;

          @media screen and (max-width: 768px) {
            font-size: 20px;
            line-height: 1;
          }
        }
      }
    }
  }

  &--color{
    &--purple {
      &:hover, &:active {
        background-color: black;

        #{$parent} {
          &__font {
            color: #9D9CF8;
          }
        }
      }
    }

    &--pink {
      &:hover, &:active {
        background-color: black;

        #{$parent} {
          &__font {
            color: #F7CDD6;
          }
        }
      }
    }
  }
}