.pages-start {
  &__container {
    margin: auto;
    max-width: 409px;
    min-height: 441px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      padding: 0 16px;
    }
  }

  &__body {
    flex-grow: 1;
    width: 100%;
    border-radius: 30px;
    background: #9D9CF8;
    display: flex;
    flex-direction: column;
    padding: 25px 30px 35px;

    @media screen and (max-width: 768px) {
      padding: 15px 20px 25px;
    }
  }

  &__tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
  }

  &__tab {
    width: fit-content;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  &__progress {
    width: 100%;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.5);
    height: 5px;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 50%;
      height: 100%;
      top: 50%;
      translate: 0 -50%;
      left: 0;
      transition: left 0.3s;
      border-radius: 20px;
      background: #000;
    }

    &--right {
      &::after {
        left: 50%;
      }
    }
  }

  &__form {
    flex-grow: 1;
    height: 100%;
  }

  &__font {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}