.played-numbers-header {
  display: flex;
  align-items: center;
  padding: 6px 0 7px 29px;
  border-radius: 15px;
  background: #FAF3EA;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 9px 7px;
  }

  &__title {
    flex-shrink: 0;
    margin-right: 25px;
  }

  &__numbers {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    overflow-x: auto;

    @media screen and (max-width: 768px) {
      gap: 10px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__number {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #9D9CF8;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  &__font {
    &--title {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }

    &--number {
      color: #FAF3EA;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
}