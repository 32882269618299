.tab-form {
  display: flex;
  flex-direction: column;
  padding-top: 32px;

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &:not(:last-child) {
      margin-bottom: 11px;
    }
  }

  &__input {
    border-radius: 15px;
    padding: 0 18px;
    background: #FAF3EA;
    border: none;
    max-width: 291px;
    width: 100%;
    height: 45px;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:focus {
      outline: none;
    }
  }

  &__button {
    width: fit-content;
    margin: auto auto 0;
  }

  &__cross-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: fit-content;
    padding: 0;
  }

  &__add-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    padding: 0;
    gap: 10px;
    cursor: pointer;
  }

  &__add {
    display: flex;
    align-items: center;
    padding: 0 18px;
    max-width: 291px;
    height: 45px;
    width: 100%;
    border-radius: 15px;
    border: 3px solid #000;
  }

  &__font {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}