.pages-main {
  &__container {
    max-width: 840px;
    width: 100%;
    margin: 17px auto 40px;

    @media screen and (max-width: 768px) {
      padding: 17px 13px 18px;
      margin: 0;
    }
  }

  &__thanks {
    margin: auto;
  }

  &__question-wrapper {
    border-radius: 15px;
    background: #F7CDD6;
    max-width: 561px;
    width: 100%;
    margin-top: 10px;
    min-height: 250px;
  }

  &__coin-wrapper {
    margin-left: 93px;
  }

  &__category {
    text-align: center;
    margin-bottom: 20px;
  }

  &__font {
    &--category {
      color: #000;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    &--number {
      color: #000;
      text-align: center;
      font-size: 64px;
      font-style: normal;
      font-weight: 800;
      line-height: 1;
      text-transform: uppercase;

      @media screen and (max-width: 768px) {
        font-size: 48px;
      }
    }
  }
}