* {
  font-family: "Regular";
}

body {
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #efd7be;
  height: 100vh;
}

code {
  font-family: "Regular", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100% !important;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-tabs .ant-tabs-tab:hover {
  color: black;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-tabs .ant-tabs-ink-bar {
  background: black;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-btn-default.ant-btn-background-ghost:disabled {
  cursor: not-allowed;
  color: gray;
  border-color: grey;
}
:where(.css-dev-only-do-not-override-j0nf2s).ant-btn-default.ant-btn-background-ghost:hover {
  color: black;
  border-color: black;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-btn-default.ant-btn-background-ghost {
  color: black;
  border-color: black;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-input:focus,
:where(.css-dev-only-do-not-override-j0nf2s).ant-input-focused {
  border-color: black;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-input:hover {
  border-color: black;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-modal .ant-modal-content {
  background-color: #ffeddd;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-modal .ant-modal-header {
  background: transparent;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: black;
  background-color: black;
}

.ant-radio-wrapper:hover
  :where(.css-dev-only-do-not-override-j0nf2s).ant-radio-wrapper,
:where(.css-dev-only-do-not-override-j0nf2s).ant-radio-wrapper:hover
  .ant-radio-inner {
  border-color: black;
}

:where(.css-dev-only-do-not-override-j0nf2s)[class^="ant-input"],
:where(.css-dev-only-do-not-override-j0nf2s)[class*=" ant-input"] {
  font-family: "Regular";
}

.ant-tabs .ant-tabs-tab {
  width: 50%;
  justify-content: center;
  font-size: 16px;
  color: gray;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: bold;
  color: black;
}

:where(.css-j0nf2s).ant-tabs .ant-tabs-tab:hover {
  color: black;
}

:where(.css-j0nf2s).ant-tabs .ant-tabs-ink-bar {
  background: black;
}

:where(.css-j0nf2s).ant-btn-default.ant-btn-background-ghost:disabled {
  cursor: not-allowed;
  color: gray;
  border-color: grey;
}
:where(.css-j0nf2s).ant-btn-default.ant-btn-background-ghost:hover {
  color: black;
  border-color: black;
}

:where(.css-j0nf2s).ant-btn-default.ant-btn-background-ghost {
  color: black;
  border-color: black;
}

:where(.css-j0nf2s).ant-input:focus,
:where(.css-j0nf2s).ant-input-focused {
  border-color: black;
}

:where(.css-j0nf2s).ant-input:hover {
  border-color: black;
}

:where(.css-j0nf2s).ant-modal .ant-modal-content {
  background-color: #ffeddd;
}

:where(.css-j0nf2s).ant-modal .ant-modal-header {
  background: transparent;
}

:where(.css-j0nf2s).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: black;
  background-color: black;
}

.ant-radio-wrapper:hover :where(.css-j0nf2s).ant-radio-wrapper,
:where(.css-j0nf2s).ant-radio-wrapper:hover .ant-radio-inner {
  border-color: black;
}

:where(.css-j0nf2s)[class^="ant-input"],
:where(.css-j0nf2s)[class*=" ant-input"] {
  font-family: "Regular";
}

@font-face {
  font-family: "Medium"; /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./Fonts/KyivTypeSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Regular"; /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./Fonts/KyivTypeSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "MakBold"; /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./Fonts/MAK-bold.otf") format("truetype");
}
