.radio {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__input {
    display: none;

    &:checked + div {
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: black;
      }
    }
  }

  &__field {
    border-radius: 50%;
    margin-right: 20px;
    border: 3px solid black;
    padding: 3px;
    width: 25px;
    height: 25px;
    flex-shrink: 0;
  }

  &__font {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
}