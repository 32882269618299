.pages-welcome {
  &__container {
    max-width: 691px;
    width: 100%;
    margin: auto;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      padding: 16px;
    }
  }

  &__wrapper {
    border-radius: 30px;
    background: #FFF;
    min-height: 335px;
    padding: 42px;
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
      padding: 25px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
    }
  }

  &__button {
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__font {
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
}