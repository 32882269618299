.player-card {
  width: 150px;
  border-radius: 15px;
  background: #FFF;
  padding: 13px 10px 10px;

  &__name {
    margin-bottom: 12px;
  }

  &__numbers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__font {
    &--name {
      color: #000;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }

    &--number {
      color: #000;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
}