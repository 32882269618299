.facts {
  padding: 24px 30px 27px;

  @media screen and (max-width: 768px) {
    padding: 13px 17px 18px;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__text {
    height: 100%;
    max-height: 250px;
    min-height: 150px;
    overflow-y: auto;
  }

  &__button {
    margin: 25px auto 0;
  }

  &__font {
    &--title {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-transform: uppercase;

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }

    &--text {
      color: #000;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 1;

      @media screen and (max-width: 768px) {
        font-size: 13px;
      }
    }
  }
}